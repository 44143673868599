




import CreateAssessment from '../components/CreateAssessment.vue';
import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  components: { CreateAssessment },
  name: 'AssessmentsIndexPage',

  setup() {
    return {};
  },
});
